import { Injectable } from '@angular/core';
import { ThemeService } from './theme.service';
import { HttpClient } from '@angular/common/http';
import { AppConfigurationModel } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AppConfigurationService {
  private configuration: AppConfigurationModel;

  constructor(private http: HttpClient, private themeService: ThemeService) {
    this.themeService.loadTheme();
  }

  async loadConfig(): Promise<void> {
    this.configuration = await this.http.get<AppConfigurationModel>('/api/appconfig').toPromise();
  }

  getPrimaryColor(): string {
    return this.configuration.primaryColor;
  }

  getSecondaryColor(): string {
    return this.configuration.secondaryColor;
  }

  getAppName(): string {
    return this.configuration.appName;
  }

  getAppUrl(): string {
    return this.configuration.appUrl;
  }

  getAsgardUrl(): string {
    return this.configuration.asgardUrl;
  }

  getAppUrlSsl(): string {
    return this.configuration.appUrlSsl;
  }

  getUnlinkEndpoint(): string {
    return this.configuration.unlinkEndpoint;
  }

  getLinkingEndpoint(): string {
    return this.configuration.linkingEndpoint;
  }

  getExternalLoginUrl(): string {
    return this.configuration.externalLoginUrl;
  }
}
