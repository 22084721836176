import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { DisplayValuePipe } from './pipes/display-value.pipe';
import { PlomoMaterialModule, MaterialModule } from '@baks/plomo-material';

const COMMON_MODULES = [CommonModule, FlexLayoutModule, RouterModule, MaterialModule, PlomoMaterialModule, ReactiveFormsModule];

@NgModule({
  declarations: [DisplayValuePipe],
  imports: [
    ...COMMON_MODULES,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [...COMMON_MODULES, TranslateModule, DisplayValuePipe],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      // providers
    };
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  try {
    return new TranslateHttpLoader(http, 'api/AppConfig/translations/', '.json');
  } catch (error) {
    throw error;
  }
}
