import { AsgardUserModel } from '@baks/types';
import { TenantVm } from '../models/tenantvm.model';

export const mapTenantCollectionApiModelToTenantVmCollection = (userModel: AsgardUserModel): TenantVm[] => {
  let tenants;

  if (!userModel) {
    tenants = [];
  } else {
    tenants = userModel.tenants;
  }

  if (!tenants || !tenants.length) {
    return [];
  }

  return tenants.map(mapOne);
};

const mapOne = (tenantApiModel): TenantVm => ({
  id: tenantApiModel.id,
  no: tenantApiModel.no,
  name: tenantApiModel.name,
  roles: tenantApiModel.roles,
  status: tenantApiModel.status,
});
