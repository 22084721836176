import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService, SidebarService } from './core/services';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subs = [];
  isLoading: boolean;
  mode$: Observable<string>;
  isOpen$: Observable<boolean>;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private sidebarService: SidebarService,
    private loadingService: LoadingService)
  {
    this.translate.setDefaultLang('en');
    this.router.events.subscribe(this.checkRouterEvents.bind(this));
  }

  ngOnInit(): void {
    this.mode$ = this.sidebarService.mode$;
    this.isOpen$ = this.sidebarService.isOpen$;
    this.subs.push(
      this.loadingService.isLoading$.subscribe(isLoading => { 
        this.isLoading = isLoading;
        this.cd.detectChanges();
      })
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    const {
      target: { innerWidth },
    } = event;
    if (innerWidth > 760) {
      this.sidebarService.changeMode('side');
    } else {
      this.sidebarService.changeMode('over');
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  private checkRouterEvents(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.loadingService.setLoading(true);
    }

    if (routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationError || routerEvent instanceof NavigationCancel) {
      this.loadingService.setLoading(false);
    }
  }
}
