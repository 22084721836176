<div *ngFor="let notif of notifications" [class]="getClasses(notif)" fxLayout="column" fxLayoutAlign="start stretch">
  <div fxFlex="10" fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="90" fxLayoutAlign="start center" fxLayoutGap="1em">
      <mat-icon>{{ notif.type | notificationIcon }}</mat-icon>
      <h4>{{ notif.title }}</h4>
    </div>
    <mat-icon class="click" (click)="removeNotification(notif)">close</mat-icon>
  </div>
  <div fxFlex="90">
    {{ notif.body }}
  </div>
</div>
