export interface CustomError {
  message: string;
  statusCode: number;
  statusText?: string;
}

export const createEmptyError = (): CustomError => ({
  message: '',
  statusCode: 0,
  statusText: '',
});

export const createError = (message, statusCode, statusText?): CustomError => ({
  message,
  statusCode,
  statusText,
});
