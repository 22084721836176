<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
<app-notification></app-notification> 
<!-- <router-outlet></router-outlet>  -->
<div fxLayout="column" class="h-100" fxLayoutAlign="start stretch">
    <app-nav-bar fxFlex="7"></app-nav-bar>
    <mat-sidenav-container fxFlex="90">
      <mat-sidenav [mode]="mode$ | async" [opened]="isOpen$ | async" hasBackdrop="false" class="sidebar">
        <app-sidebar-menu></app-sidebar-menu>
      </mat-sidenav>
      <mat-sidenav-content>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  
