import { SidebarModel } from '../models';
import { SidebarService } from '../services';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent implements OnInit {
  content?: SidebarModel[];

  constructor(private sidebarContent: SidebarService) {}

  ngOnInit(): void {
    this.sidebarContent.content$().subscribe((content) => {
      this.content = content;
    });
  }

  isActive(route: SidebarModel): any {
    if (!route.disabled && this.normalizeText(route.url) === this.normalizeText(window.location.pathname)) {
      return 'active-link';
    }

    return '';
  }

  private normalizeText(text: string): string {
    return text.replace(/\W/g, '').trim();
  }
}
