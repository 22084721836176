import { TenantVm } from './tenantvm.model';

export interface UserResponse {
  status: number,
  message: string,
  user: UserVm
}

export interface UserVm {
  id?: string;
  name?: string;
  email?: string;
  userName?: string;
  lockoutEnd?: Date;
  roleList?: string[];
  phoneNumber?: string;
  tenants?: Array<TenantVm>;
}

export interface UserLoginVm {
  username: string;
  password: string;
}

export const createDefaultUserVm = () => ({
  id: '-1',
  email: '',
  roles: [],
  userName: '',
  tenants: [],
});

export interface FederatedClient {
  id: string;
  code: string;
  name: string;
  userId: string;
  userFk: string;
  clientId: string;
  clientSecret: string;
  unlinkUrl: string;
  linkingUrl: string;
  matchingUrl: string;
  authorizeUrl: string;
  validateUserUrl: string;
  validateTokenUrl: string;
}

export interface FederatedClientResponse {
  status: number;
  message: string;
  federatedClient: FederatedClient;
}

export interface FederatedClientListResponse {
  status: number;
  message: string;
  federatedClients: Array<FederatedClient>;
}