import { AsgardUserModel } from '@baks/types';
import { createDefaultUserVm, UserVm } from '../models';
import { mapTenantCollectionApiModelToTenantVmCollection } from './tenantApiModelToTenantVm.mapper';

export const mapUserApiModelToUserVm = (userApiModel: AsgardUserModel): UserVm => {
  if (!userApiModel) {
    return createDefaultUserVm();
  }

  return {
    id: userApiModel.id,
    email: userApiModel.email,
    userName: userApiModel.userName,
    phoneNumber: userApiModel.phoneNumber,
    tenants: mapTenantCollectionApiModelToTenantVmCollection(userApiModel),
  };
};
