import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notificationIcon',
})
export class NotificationIconPipe implements PipeTransform {
  transform(notificationType: string): string {
    switch (notificationType) {
      case 'Warn':
        return 'warning';
      case 'Success':
        return 'check_circle';
      case 'Error':
        return 'error_outline';
      default:
        return 'info';
    }
  }
}
