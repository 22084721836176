const THREE_SECONDS = 3000;

export class NotificationModel {
  public type: string;
  public title: string;
  public body: string;

  constructor({ type, title, body }: { type: string, title: string, body: string }) {
      this.type = type;
      this.title = title;
      this.body = body;
  }
}

export interface NotificationVm {
  type: string;
  title: string;
  body: string;
  id?: number;
  timeout?: number;
}

export const enum NotificationType {
  Info = 'Info',
  Success = 'Success',
  Warn = 'Warn',
  Error = 'Error',
}

export const createSuccessNotification = (title: string, body: string, timeout?: number): NotificationVm => ({
  timeout: timeout ? timeout : THREE_SECONDS,
  title,
  body,
  type: NotificationType.Success,
});

export const createErrorNotification = (title: string, body: string, timeout?: number): NotificationVm => ({
  timeout: timeout ? timeout : THREE_SECONDS,
  title,
  body,
  type: NotificationType.Error,
});

export const createWarningNotification = (title: string, body: string, timeout?: number): NotificationVm => ({
  timeout: timeout ? timeout : THREE_SECONDS,
  title,
  body,
  type: NotificationType.Warn,
});

export const createInfoNotification = (title: string, body: string, timeout?: number): NotificationVm => ({
  timeout: timeout ? timeout : THREE_SECONDS,
  title,
  body,
  type: NotificationType.Info,
});

export const createCustomNotification = (title: string, body: string, type: NotificationType, timeout?: number): NotificationVm => ({
  timeout: timeout ? timeout : THREE_SECONDS,
  title,
  body,
  type,
});
