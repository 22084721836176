import { Injectable } from '@angular/core';
import { NotificationVm } from '../models';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConfigurationService } from './app-configuration.service';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private connection: HubConnection;
  private notifSubject: Subject<NotificationVm> = new Subject<NotificationVm>();
  private notificationIds: number = 0;

  constructor(private appConfig: AppConfigurationService) {}

  connect(): void {
    this.connection = new HubConnectionBuilder().withUrl(`${this.resolveSignalRRoute()}notify`).build();

    this.connection
      .start()
      .then(() => {
        console.log('Connection established.');
      })
      .catch((err) => {
        console.log('Connection error -> ', err);
      });

    this.connection.on('BroadcastMessage', (msg: NotificationVm) => {
      this.notifSubject.next(msg);
    });
  }

  public addMessage(notification: NotificationVm): void {
    notification.id = this.notificationIds++;
    this.notifSubject.next(notification);
  }

  private resolveSignalRRoute(): string {
    return environment.production ? this.appConfig.getAppUrlSsl() : this.appConfig.getAppUrl();
  }

  notifications$(): Observable<NotificationVm> {
    return this.notifSubject.asObservable();
  }
}
