import { Injectable } from '@angular/core';
import { LoadingService } from './loading.service';
import { UserLoginVm, UserResponse } from '../models';
import { map, switchMap, flatMap } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BrowserStorageService } from './browser-storage.service';
import { Observable, BehaviorSubject, of, zip, throwError } from 'rxjs';
import { mapUserApiModelToUserVm } from '../mappers/userApiModelToUserVm.mapper';
import { UserVm, createDefaultUserVm, createErrorNotification } from '../models';
import { AsgardUserModel, AsgardUserResponseModel, AsgardResponseModel } from '@baks/types';

type AsgardResponseModelAndUser = { asgardResponse: AsgardResponseModel; userInfo: AsgardUserResponseModel };

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private _isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private currentUser: BehaviorSubject<UserVm> = new BehaviorSubject(createDefaultUserVm());

  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
    private storage: BrowserStorageService,
    private notificationService: NotificationService)
  {

  }

  doLogin(userLogin: UserLoginVm): Observable<AsgardResponseModel> {
    return this.http.post<AsgardResponseModel>('/api/login', userLogin).pipe(
      flatMap(this.processAsgardResponse.bind(this)),
      switchMap<AsgardResponseModel, Observable<any[]>>(this.getUserInfo.bind(this)),
      map<any[], AsgardResponseModelAndUser>(([asgardResponse, userInfo]) => ({
        asgardResponse,
        userInfo,
      })),
      flatMap<AsgardResponseModelAndUser, Observable<AsgardResponseModel>>(this.processGetUser.bind(this)),
      map<AsgardResponseModel, AsgardResponseModel>(this.finishLoginProcess.bind(this)),
    );
  }

  doLogout() {
    this.storage.clearAllStorage();
    this.storage.deleteAllCookies();
    this._isLoggedIn.next(false);
    this.currentUser.next(undefined);
  }

  doAsgardLogout() {
    return this.http.get<AsgardResponseModel>('/api/login/logout');
  }

  isLoggedIn$(): Observable<boolean> {
    return this._isLoggedIn.asObservable();
  }

  currentUser$(): Observable<UserVm> {
    return this.currentUser.asObservable();
  }

  isLoggedIn(): boolean {
    return this._isLoggedIn.getValue();
  }

  private processAsgardResponse(asgardResponse: AsgardResponseModel): Observable<AsgardResponseModel> {
    const { sessionId, accessToken } = asgardResponse;
    
    if (accessToken == null || accessToken === '') {
      return throwError({ message: 'Received accessToken was empty', statusCode: 500 });
    }

    this.storage.createCookie('id_token', accessToken);
    this.storage.createCookie('access_token', sessionId);
    return of(asgardResponse);
  }

  private getUserInfo(asgardResponse: AsgardResponseModel): any {
    const userDetails$: Observable<AsgardUserModel> = this.http.get<AsgardUserModel>('/api/login/GetUser');
    return zip(of(asgardResponse), userDetails$);
  }

  // check the response status and sets the currentUser retrieved from the server
  private processGetUser({ asgardResponse, userInfo }: AsgardResponseModelAndUser): Observable<AsgardResponseModel> {
    this.loadingService.setLoading(true);
    if (userInfo.status !== 200) {
      return throwError({ message: userInfo.message, statusCode: userInfo.status });
    }

    const userMapped = mapUserApiModelToUserVm(userInfo.user);
    this.currentUser.next(userMapped);

    return of(asgardResponse);
  }

  private finishLoginProcess(asgardResponse: AsgardResponseModel): AsgardResponseModel {
    this._isLoggedIn.next(true);
    this.loadingService.setLoading(false);
    return { ...asgardResponse };
  }

  private validateToken(token: string) {
    const params = new HttpParams().append('token', token);
    this.http.get<AsgardResponseModel>('/api/login/ValidateToken', {params})
      .toPromise()
      .then((response: AsgardResponseModel) => {
        if (response.valid && response.status === 200) {
          this.storage.createCookie('id_token', response.accessToken);
        }
      })
      .catch((error) => {
        this.notificationService.addMessage(
          createErrorNotification('Error validating token', error.error.message));
    });
  }

  singleSignOn$(): Observable<UserResponse> {
    let getUser$: Observable<UserResponse>;
    let accessToken = this.storage.getCookie('access_token');

    if (accessToken) {
      this.validateToken(accessToken);
      getUser$ = this.http.get<UserResponse>('/api/login/GetUser');
    }

    return getUser$;
  }

  setIsLoggedIn(logged: boolean) {
    this._isLoggedIn.next(logged);
  }

  setCurrentUser(user: UserVm) {
    this.currentUser.next(user);
  }
}
