import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Route } from '@angular/router';
import { SidebarModel, createSideBar } from '../models';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private isOpen = new BehaviorSubject<boolean>(false);
  private mode = new BehaviorSubject<string>('push');
  private content = new Subject<SidebarModel[]>();
  private disable = new Subject<boolean>();
  disable$ = this.disable.asObservable();
  isOpen$ = this.isOpen.asObservable();
  mode$ = this.mode.asObservable();

  content$() {
    return this.content.asObservable();
  }

  setContent(mainRoute: string, childRoutes: Route[]): void {
    const routePrefix: string = `/${mainRoute}`;
    const sideBarList: SidebarModel[] = childRoutes.map((route) => {
      const content: string = route.data ? route.data.title : '';
      const url: string = `${routePrefix}/${route.path}`;
      const disabled: boolean = route.data ? route.data.disabled : false;
      const icon: any = route.data ? route.data.icon : null;
      return createSideBar({ content, url, disabled, icon });
    });

    this.content.next(sideBarList);
  }

  disableSidebar(url: string): void {
    if (url === '/login' || url === '/auth') {
      this.disable.next(false);
    } else {
      this.disable.next(true);
    }
  }

  openSideMenu() {
    this.isOpen.next(true);
  }

  closeSideMenu() {
    this.isOpen.next(false);
  }

  toggleSideMenu() {
    this.isOpen.next(!this.isOpen.value);
  }

  changeMode(mode: string) {
    this.mode.next(mode);
  }
}
