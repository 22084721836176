import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BrowserStorageService, LoginService } from '../core/services';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, NavigationExtras } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private loginService: LoginService, private storage: BrowserStorageService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let token = next.queryParams['token'];
    if (token) {
      this.storage.createCookie('access_token', token);
    }

    if (this.loginService.isLoggedIn()) {
      return true;
    }

    const extras: NavigationExtras = {
      skipLocationChange: true,
      queryParams: { recursiveUrl: state.url, path: next.url[0].path }

    };
    
    this.router.navigate(['/auth'], extras);
    return false;
  }
}
