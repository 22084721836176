import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/core/services';
import { NotificationVm } from '../models';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  notifications: NotificationVm[] = [];

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    this.notificationService.notifications$().subscribe((notification: NotificationVm) => {
      this.notifications.push(notification);

      if (notification.timeout !== 0) {
        setTimeout(() => this.removeNotification(notification), notification.timeout);
      }
    });
  }

  getClasses(item): string {
    return `notification ${item.type}`;
  }

  removeNotification(notification: NotificationVm) {
    this.notifications = this.notifications.filter((n) => n.id !== notification.id);
  }
}
