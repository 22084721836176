import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RequestInterceptor } from './interceptor/request-interceptor';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { SharedModule, HttpLoaderFactory } from './shared/shared.module';
import { NotificationIconPipe } from './core/pipes/notification-icon.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppConfigurationService, NotificationService } from './core/services';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NavBarComponent, NotFoundComponent, SidebarMenuComponent, NotificationComponent, LoadingSpinnerComponent } from './core/components';

const STATIC_IMPORTS = [
  BrowserAnimationsModule,
  BrowserModule,
  HttpClientModule,
  SharedModule.forRoot(),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
    isolate: false,
  }),
];

const ROUTES_IMPORTS = [AppRoutingModule];

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    NotFoundComponent,
    SidebarMenuComponent,
    NotificationComponent,
    LoadingSpinnerComponent,
    NotificationIconPipe
  ],
  imports: [...STATIC_IMPORTS, ...ROUTES_IMPORTS],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfig: AppConfigurationService, notifService: NotificationService) => () => {
        return appConfig.loadConfig().then(() => {
          notifService.connect();
        });
      },
      multi: true,
      deps: [AppConfigurationService, NotificationService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
