<mat-toolbar class="app-toolbar" [style.--primary-color]="primaryColor">
  <button mat-icon-button name="menu" (click)="sidebarMenuToggle()" [disabled]="!(isLoggedIn$ | async)">
    <mat-icon>menu</mat-icon>
  </button>
  <h1 fxHide.lt-md>{{ appName }}</h1>
  <span fxHide.lt-md class="app-toolbar-spacer"></span>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
    <!-- Login/Logout button -->
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.5em" *ngIf="isLoggedIn$ | async"></div>
    <button mat-button [matMenuTriggerFor]="menu" *ngIf="isLoggedIn$ | async">
      {{ 'modules.configuration.title' | translate }}
    </button>
  </div>
</mat-toolbar>

<!-- Configuration Menu -->
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="doLogout()">
    <mat-icon>lock</mat-icon>
    <span>{{ 'modules.main.logout' | translate }}</span>
  </button>
</mat-menu>
