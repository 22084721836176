import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { createErrorNotification, createInfoNotification } from '../models';
import { LoginService, AppConfigurationService, SidebarService, NotificationService } from '../services';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  appName: string;
  primaryColor: string;
  isLoggedIn$: Observable<boolean>;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private appConfig: AppConfigurationService,
    private sideBarMenuService: SidebarService,
    private notificationService: NotificationService)
  {
    
  }

  ngOnInit() {
    this.appName = this.appConfig.getAppName();
    this.isLoggedIn$ = this.loginService.isLoggedIn$();
    this.primaryColor = this.appConfig.getPrimaryColor();
  }

  sidebarMenuToggle(): void {
    this.sideBarMenuService.toggleSideMenu();
  }

  doLogout(): void {
    this.loginService.doAsgardLogout().toPromise().then(() => {
      this.loginService.doLogout();
      this.router.navigate(['/auth']);
      this.sideBarMenuService.closeSideMenu();
      this.notificationService.addMessage(createInfoNotification('Info', 'Session closed'));
    }).catch((error) => {
      this.notificationService.addMessage(createErrorNotification('Error', error.error.message));
    });
  }
}
